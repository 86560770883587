.in-progress {
  width: 98%;

  .enemy-hands {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .enemy-hand {
      display: flex;
      flex-direction: column;
      align-items: center;

      .player-name {
        color: $orange;
      }

      .cards {
        display: flex;
        flex-direction: row;

        .deck:not(:first-child) {
         margin-left: -$playing-card-width / 1.4;
        }
      }
    }
  }

  .piles {
    display: flex;
    flex-direction: row;

    .pile {
      display: flex;
      flex-direction: column;

      .playing-card:not(:first-child) {
        margin-top: -$playing-card-height / 1.4;
      }
    }
  }

  .fixed {
    position: fixed;
    width: 100%;
    bottom: -$playing-card-height / 2;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .draw-area {
    display: flex;
    flex-direction: row;

    > .deck {
      margin-left: 10px;
    }
  }

  .action-buttons {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    > .button {
      &:not(:first-child) {
        border-left: 1px solid $black;
      }

      &:not(:last-child) {
        border-right: 1px solid $black;
      }
    }
  }

  .directions {
    color: $orange;
  }
}
