.app-header {
  background-color: $blue;
  width: 100%;

  display: flex;
  flex-direction: row;

  color: $black;
  justify-content: space-between;

  > .title {
    margin-left: 15px;
    cursor: pointer;
  }

  > .action-button {
    margin-right: 15px;
    cursor: pointer;
  }
}
