.waiting {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: $orange;
    font-size: 4em;
    margin-bottom: 0;
  }

  > .player-label {
    color: $blue;
  }

  > .player-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    > .player-name {
      margin-top: 0px;
      color: $blue;
    }
  }

  > .button {
    margin-top: 20px;
  }
}
