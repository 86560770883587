@mixin playing-card {
  border: 1px solid black;
  background-color: $white;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: $playing-card-width;
  height: $playing-card-height;

  &.highlighted {
    border: 3px solid $orange;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  &.zoomed {
    transform: scale(1.1);
  }
}
