.app-footer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;

  position: fixed;
  bottom: 15px;

  > .footer-link {
    text-decoration: none;
    color: $blue;
  }
}
