.login-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000,.7);
  z-index: 10;

  display: flex;

  > .content {
    background-color: $white;
    margin: auto;
    width: 50%;
    max-width: 500px;

    display: flex;
    flex-direction: column;

    align-items: center;
    padding: 10px;
    padding-bottom: 20px;

    > .tab-selector {
      display: flex;
      flex-direction: row;
      width: 100%;

      justify-content: space-between;
      max-width: $content-max-width;
      margin-bottom: 10px;

      > .tab-button {
        color: $blue;
        font-size: 1.5em;
        cursor: pointer;

        &:hover {
          color: rgba($blue, .8);
        }
      }

      > .selected {
        text-decoration: underline;
      }
    }

    > * {
      margin-top: 10px;
    }

    > .x {
      align-self: flex-start;
      cursor: pointer;
      font-weight: 600;
      margin-left: 10px;
      font-size: 1.2em;
    }

     > .switch {
       cursor: pointer;
     }
  }
}
