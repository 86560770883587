.screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000,.7);
  z-index: 10;
  margin: 0;

  display: flex;

  .loader {
    margin: auto;
    //margin: 100px auto 0;
    width: 150px;
    text-align: center;

    > * {
      width: 36px;
      height: 36px;
      background-color: $green;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bubbledelay 1.2s infinite ease-in-out both;
      animation: sk-bubbledelay 1.2s infinite ease-in-out both;
    }

    > .bubble1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    > .bubble2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bubbledelay {
      0%, 80%, 100% { -webkit-transform: scale(0) }
      40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bubbledelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
    }
  }
}
