.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: $orange;
    font-size: 4em;
  }
}
