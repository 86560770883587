.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: $orange;
    font-size: 4em;
  }

  > .tab-card {
    display: flex; flex-direction: column;
    align-items: center;

    min-width: 400px;

    > .tab-selector {
      display: flex;
      flex-direction: row;
      width: 100%;

      justify-content: space-around;
      margin-bottom: 10px;

      > .tab-button {
        color: $green;
        cursor: pointer;

        &:hover {
          color: rgba($green, .8);
        }
      }

      > .selected {
        text-decoration: underline;
      }
    }

    > .tab-content {
      display: flex; flex-direction: column;
      align-items: center;
      width: 100%;

      > * {
        margin-top: 20px;
      }
    }
  }
}
