.playing-card {
  @include playing-card;

  > .number, > .suit {
    margin: 5px;
    font-size: 24px;
  }

  > .suit {
    margin-left: 0px;
  }

  &.hearts, &.diamonds {
    color: red;
  }
}
