.button {
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  border: none;

  font-size: 1em;
  font-weight: bold;

  &.disabled {
    cursor: auto;
  }
}
