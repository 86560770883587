$black: #2A2D34;
$blue: #009DDC;
$orange: #F26430;
$purple: #d86bff;
$green: #08ffbe;
$white: #FFFFFF;

$playing-card-height: 110px;
$playing-card-width: 75px;

$content-max-width: 280px;
